
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code4 } from "@/views/resources/documentation/element-ui/basic/space/code";

export default defineComponent({
  name: "customized-size",
  data() {
    return {
      size: 20
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code4
    };
  }
});
