
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/basic/space/BasicUsage.vue";
import EUIVerticalLayout from "@/views/resources/documentation/element-ui/basic/space/VerticalLayout.vue";
import EUIControlTheSizeOfTheSpace from "@/views/resources/documentation/element-ui/basic/space/ControlTheSizeOfTheSpace.vue";
import EUICustomizedSize from "@/views/resources/documentation/element-ui/basic/space/CustomizedSize.vue";
import EUIAutoWrapping from "@/views/resources/documentation/element-ui/basic/space/AutoWrapping.vue";
import EUISpacer from "@/views/resources/documentation/element-ui/basic/space/Spacer.vue";
import EUIAlignment from "@/views/resources/documentation/element-ui/basic/space/Alignment.vue";

export default defineComponent({
  name: "space",
  components: {
    EUIBasicUsage,
    EUIVerticalLayout,
    EUIControlTheSizeOfTheSpace,
    EUICustomizedSize,
    EUIAutoWrapping,
    EUISpacer,
    EUIAlignment
  },
  setup() {
    setCurrentPageTitle("Space");
  }
});
